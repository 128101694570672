<template>
  <div class="activity-stats px-10 py-7">
    <div class="radius-5 activity-cover b-cover mb-3 pa-3">
      <div class="justify-space-between d-flex">
        <div>
          <div class="d-flex">
            <base-input
              class="mr-5"
              v-for="language in $root.languages"
              :key="language"
              :placeholder="language"
              v-model="newFilter.name[language]"
            ></base-input>
            <v-btn
              class="d-block mb-2"
              :disabled="
                $root.languages
                  .map((language) => {
                    return (
                      newFilter.name[language] === '' ||
                      newFilter.name[language] == undefined
                    );
                  })
                  .includes(true)
              "
              @click="addFilterHandler"
              >Add Filter</v-btn
            >
          </div>
        </div>
        <div>
          <base-input
            class="mr-4"
            placeholder="Search Filter"
            @input="filterSearch"
          ></base-input>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="filters"
        item-key="_id"
        show-expand
        hide-default-footer
        @item-expanded="rowSelected"
        fixed-header
        height="calc(100vh - 80px)"
        :expanded.sync="expandedIDs"
        :items-per-page="-1"
      >
        <template v-slot:item.name="{ item }">
          <div class="d-flex">
            <base-input
              class="d-block mr-5"
              :value="item.name[language]"
              v-model="item.name[language]"
              v-for="language in $root.languages"
              :key="language"
              :placeholder="language"
            ></base-input>
          </div>
        </template>
        <template v-slot:item.save="{ item }">
          <v-btn @click="editFilter(item)" :disabled="!filterIsModified(item)"
            >Save</v-btn
          >
        </template>
        <template v-slot:item.delete="{ item }">
          <v-icon color="error" @click="deleteFilterConfirm(item)"
            >mdi-delete</v-icon
          >
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="ma-0">
            <div class="d-flex ml-14 mt-2">
              <base-input
                class="d-block mr-10"
                v-model="item.newProp.name[language]"
                v-for="language in $root.languages"
                :key="language"
                :placeholder="language"
              ></base-input>
              <v-btn
                class="d-block ml-2 mb-2"
                :disabled="item.newProp.name[$root.language] == ''"
                @click="addPropHandler(item)"
                >Add Prop</v-btn
              >
            </div>
            <v-data-table
              :headers="propsHeaders"
              :items="item.props"
              item-key="_id"
              hide-default-footer
              hide-default-header
              :items-per-page="-1"
              :item-class="
                () => {
                  return 'disable-hover';
                }
              "
            >
              <template v-slot:item.name="prop">
                <div class="d-flex">
                  <base-input
                    class="d-block ml-10"
                    :value="prop.item.name[language]"
                    v-model="prop.item.name[language]"
                    style="float: left"
                    v-for="language in $root.languages"
                    :key="language"
                    :placeholder="language"
                  ></base-input>
                  <v-btn
                    class="d-block ml-4"
                    @click="editProp(prop.item)"
                    :disabled="!propIsModified(item, prop.item)"
                    >Save</v-btn
                  >
                  <v-icon
                    class="d-block ml-2 mt-1"
                    color="error"
                    @click="deletePropConfirm(prop.item)"
                    >mdi-delete</v-icon
                  >
                </div>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="deleteFilterDialog" width="300">
      <delete-confirm
        :key="deleteFilterDialog"
        @confirmAccess="deleteFilterHandler"
        @closeDialog="deleteFilterDialog = false"
      ></delete-confirm>
    </v-dialog>
    <v-dialog v-model="deletePropDialog" width="300">
      <delete-confirm
        :key="deletePropDialog"
        @confirmAccess="deletePropHandler"
        @closeDialog="deletePropDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    newFilter: {
      name: {}
    },
    filters: [],
    originalFilters: [],
    obj: {
      multiSelect: true
    },
    headers: [
      { text: "Filter", align: "left", value: "name" },
      {
        text: "Save",
        align: "center",
        value: "save",
        sortable: false,
        width: "2%"
      },
      {
        text: "Delete",
        align: "center",
        value: "delete",
        sortable: false,
        width: "4%"
      }
    ],
    propsHeaders: [{ text: "Name", align: "left", value: "name" }],
    expandedIDs: [],
    deleteFilterDialog: false,
    deletingFilter: undefined,
    deletePropDialog: false,
    deletingProp: undefined,
    searchWord: ""
  }),
  methods: {
    async addFilterHandler() {
      if (this.newFilter.name) {
        let reqObj = {
          inputType: 1,
          name: this.newFilter.name,
          userInputType: "checkbox",
          multiSelect: true
        };
        let res = await this.$axios.post(`/filters`, reqObj);

        if (res && res.data) {
          this.$store.dispatch("showSnack", {
            text: "Added successfully",
            color: "success"
          });
          await this.fetchFilters();
        } else {
          this.$store.dispatch("showSnack", {
            text: res.error,
            color: "error"
          });
        }
      }
    },
    async addPropHandler(filter) {
      filter.newProp.filterType = filter._id;
      let res = await this.$axios.post(`/props`, filter.newProp);

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: this.isEdit ? "Edited successfully " : "Added successfully",
          color: "success"
        });
        filter.newProp.name = "";
        this.fetchPropsFor(filter);
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    async filterSearch(searchWord) {
      this.filters = [];
      this.searchWord = searchWord;
      await this.fetchFilters();
    },
    async fetchFilters() {
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      let { data } = await this.$axios.get(
        `filters?searchWord=${this.searchWord || ""}&sort=4`
      );
      if (data) {
        this.filters = data.map((item) => {
          return {
            ...item,
            newProp: { name: { en: "", ar: "" } },
            props: [],
            originalProps: []
          };
        });
        this.originalFilters = data.map((item) => {
          return { _id: item._id, name: { ...item.name } };
        });
      }
    },
    async rowSelected(row) {
      if (row.value) {
        this.fetchPropsFor(row.item);
      }
    },
    async fetchPropsFor(filter) {
      let { data } = await this.$axios.get(`props?filterType=${filter._id}`);
      if (data) {
        filter.props = data;
        filter.originalProps = data.map((item) => {
          return {
            _id: item._id,
            name: {
              ...item.name
            }
          };
        });
      }
      this.expandedIDs.push(filter._id);
    },
    filterIsModified(filter) {
      var isModified = false;
      this.$root.languages.forEach((language) => {
        if (
          filter.name[language] !=
          this.originalFilters.find((item) => {
            return item._id === filter._id;
          })?.name[language]
        ) {
          isModified = true;
        }
      });
      return isModified;
    },
    async editFilter(filter) {
      let reqObj = {
        inputType: 1,
        name: filter.name,
        userInputType: "checkbox",
        multiSelect: true
      };
      let res = await this.$axios.put(`/filters/${filter._id}`, reqObj);

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: this.isEdit ? "Edited successfully " : "Added successfully",
          color: "success"
        });
        this.addFilterDialog = false;
        await this.fetchFilters();
        this.expandedIDs = this.expandedIDs.filter((item) => {
          return item._id != filter._id;
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    propIsModified(filter, prop) {
      var isModified = false;
      this.$root.languages.forEach((language) => {
        if (
          prop.name[language] !=
          filter.originalProps?.find((item) => {
            return item._id === prop._id;
          })?.name[language]
        ) {
          isModified = true;
        }
      });
      return isModified;
    },
    async editProp(prop) {
      let res = await this.$axios.put(`/props/${prop._id}`, prop);

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: this.isEdit ? "Edited successfully " : "Added successfully",
          color: "success"
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    async deletePropConfirm(prop) {
      this.deletePropDialog = true;
      this.deletingProp = prop;
    },
    async deletePropHandler() {
      if (this.deletingProp) {
        const res = await this.$axios.delete(`/props/${this.deletingProp._id}`);

        if (res && res.data) {
          this.$store.dispatch("showSnack", {
            text: "deleted successfully",
            color: "success"
          });
          this.deletePropDialog = false;
          const filter = this.filters.find((item) => {
            return item._id == this.deletingProp.filterType;
          });
          this.fetchPropsFor(filter);
        } else {
          this.$store.dispatch("showSnack", {
            text: res.error,
            color: "error"
          });
        }
      }
    },
    deleteFilterConfirm(item) {
      this.deleteFilterDialog = true;
      this.deletingFilter = item;
    },
    async deleteFilterHandler() {
      if (this.deletingFilter) {
        const res = await this.$axios.delete(
          `/filters/${this.deletingFilter._id}`
        );

        if (res && res.data) {
          this.$store.dispatch("showSnack", {
            text: "deleted successfully",
            color: "success"
          });
          this.deleteFilterDialog = false;
          await this.fetchFilters();
        } else {
          this.$store.dispatch("showSnack", {
            text: res.error,
            color: "error"
          });
        }
      }
    }
  },
  async created() {
    await this.fetchFilters();
  }
};
</script>

<style lang="scss">
.activity-stats {
  background: #f2f2f269;
  height: 100vh;
  overflow: hidden;
  .activity-cover {
    background: white;
  }
}
.v-data-table__expanded__content {
  box-shadow: none !important;
}
.disable-hover:hover {
  background-color: inherit !important;
}
</style>
